.footer {
    background-color: lighten($color-blue, 4%);
    padding: 40px 0;

    a {
        display: inline-block;
        margin: 0 5px;
    }

    h3 {
        color: white;
        text-transform: uppercase;
        margin-bottom: 20px;
        font-size: 1.3rem;
    }

    &__infos {
        margin-top: 20px;

        a {
            display: flex;
            align-items: center;
            color: white;
            margin: 10px 0;;

            img {
                margin-right: 10px;
                max-width: 15px;
            }
        }
    }

    ul {
        list-style: initial;
        color: white;
        margin-left: 15px;

        li {
            opacity: 0.7;
            transition: 0.25s all;

            &:hover {
                opacity: 1;
            }
        }

        a {
            margin: 5px 0;
            color: rgb(255, 255, 255);
        }
    }
}

.copyright {
    padding: 5px 0;
    background-color: darken($color-blue, 1%);
    align-items: center;
    p{
        text-align: center;
        font-weight: normal;
        font-size: 0.9rem;
        color:#98b7ca;
        margin:0;
        img {
            width: 5%;
            display: inline-block;
        }
    }
}

@media screen and (max-width: 991px) {
    .footer {
        h3 {
            font-size: 1.2rem;
        }

        ul {
            a {
                margin: 3px 0;
            }
        }

        &__infos {
            a {
                font-size: 0.9rem;

                img {
                    max-width: 13px;
                }
            }
        }
    }
}


@media screen and (max-width: 767px) {
    .footer {
        .col-12 {
            text-align: left;
        }

        &__infos {
            a {
                margin: 3px 0;
            }
        }

        h3 {
            margin: 20px 0
        }
    }
    .copyright {
        background-color: darken($color-blue, 1%);
        align-items: center;
        padding:15px 0;
        p{
            margin:0 auto;
            font-size: 0.8rem;
            line-height: 1.2;
            max-width: 80%;
            img {
                width: 20%;
                display: inline-block;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .footer {
        h3 {
            font-size: 1.1rem;
            display:none;
        }

        &__infos {
            a {
                font-size: 1rem;
                justify-content:flex-start;
            }
        }

        ul {
            margin-top:40px;
            a {
                font-size: 1rem;
            }
        }
    }
}