.fale-conosco {
    background-image: url(../images/form-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .section-title {
        margin-bottom: 30px;
        font-weight: 700;
        text-align: left;
    }

    img {
        max-width: 80%;
        margin: 0 auto;
    }

    &__form {
        .erro {
            color: red;
            font-weight: bolder;
        }
        .success{
            color: green;
            font-weight: bolder;
        }
        .aErro{
            color: red;
            display: inline-block;
            position: absolute;
            top:10px;
            right:10px;
        }
        .border{
            //border:2px solid red !important;
            box-shadow: -6px 11px 0px 0px red;
        }
        input,
        textarea {
            border: none;
            outline: none;
            background-color: white;
            color: $color-blue;
            width: 100%;
            font-size: 1rem;
            line-height: 1.5;
            height: auto;
            padding: 7px 10px;
            margin: 10px 0;
            box-shadow: -6px 11px 0px 0px #025586;
        }

        textarea {
            min-height: 90px;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-blue;
            opacity: 1;
            /* Firefox */
            font-weight: 600;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $color-blue;
            font-weight: 600;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $color-blue;
            font-weight: 600;
        }

        input[type="submit"] {
            background-color: $color-blue;
            text-transform: uppercase;
            color: white;
            font-size: 1.3rem;
            padding: 20px 0;
            box-shadow: none;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 991px) {
    .fale-conosco {
        img {
            margin-top: 30px;
        }
    }
}

@media(max-width:767px) {
    .fale-conosco {
        .section-title {
            text-align: center;
        }

        form {
            max-width: 400px;
            margin: 0 auto;
        }
    }
}