#popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1001;
    justify-content: center;
    align-items: center;

    .form-popup {
        position: relative;
        height: auto;
        width: 750px;
        padding: 50px;
        z-index: 999;
        background-color: $color-blue;

        #popup-close {
            color: white;
            position: absolute;
            top: -10px;
            right: 20px;
            font-size: 3rem;
            font-weight: bolder;
            cursor: pointer;
        }

        .simulacao__email {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            h1,
            h4 {
                width: 100%;
                text-align: center;
            }

            h1 {
                color: $color-green;
                font-size: 3rem;
                font-weight: bolder;
                margin-bottom: 20px;
            }

            h4 {
                color: white;
                font-size: 1.2rem;
                font-weight: normal;
                margin-bottom: 30px;
                line-height: 1.3;
            }

            .erro {
                color: red;
                font-weight: bolder;
            }

            .success {
                color: white;
                font-weight: bolder;
            }

            .aErro {
                color: red;
                display: inline-block;
                position: absolute;
                top: 10px;
                right: 10px;
            }

            .border {
                //border:2px solid red !important;
                box-shadow: -6px 11px 0px 0px red;
            }

            input,
            textarea {
                border: none;
                outline: none;
                background-color: white;
                color: $color-blue;
                width: 100%;
                font-size: 1rem;
                line-height: 1.5;
                height: 50px;
                padding: 7px 10px;
                margin: 10px 0;
                box-shadow: -6px 11px 0px 0px #025586;
            }

            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $color-blue;
                opacity: 1;
                /* Firefox */
                font-weight: 600;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $color-blue;
                font-weight: 600;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $color-blue;
                font-weight: 600;
            }

            input[type="submit"] {
                background-color: $color-green;
                text-transform: uppercase;
                color: white;
                font-size: 1.3rem;
                padding: 10px 0;
                box-shadow: none;
                margin-top: 20px;
                display: inline-block;
                width: 200px;
            }

        }
    }

}

@media(max-width: 767px) {
    #popup {
        .form-popup {
            #popup-close {
                font-size: 2.5rem;
            }

            .simulacao__email {
                h1 {
                    font-size: 2.5rem;
                }
                h4{
                   font-size: 1rem; 
                }
                
            }

        }
    }

}


@media(max-width: 575px) {
    #popup {
        .form-popup {
            padding: 50px 10px;
            #popup-close {
                font-size: 2.5rem;
                right:10px;
            }

            .simulacao__email {
                h1 {
                    font-size: 2rem;
                }
                h4{
                    font-size: .8rem;
                }
                
                input,
                textarea {
                    font-size: .8rem;
                    height: 35px;
                }
                input[type="submit"] {
                    padding: 5px 0;
                   
                }
            }

        }
    }

}