@keyframes slideshow-img-downscaling {
    from {
        transform: scale(1.25)
    }
    to {
        transform: scale(1)
    }
}
@keyframes slideshow-img-upscaling {
    from {
        transform: scale(0.8)
    }
    to {
        transform: scale(1)
    }
}