.header {
    background: $color-green;
    color: white;
    position: relative;
    z-index: 2;
    height: 60px;

    &__logo {
        display: block;
        position: absolute;

        img {
            background: white;
            padding: 15px 30px 25px;
        }
    }

    
    &__link {
        color: $color-blue;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 600;
        -webkit-transition: 0.5s all ease;
        -o-transition: 0.5s all ease;
        transition: 0.5s all ease;
        width: 100%;
        display: inline-block;
        padding: 10px 0;
        border-bottom: 1px solid #ffffff1a;
        &:hover{
            border-bottom: 1px solid #ffffff1a;
            color:white;

        }
    }

    &__links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 0;
        margin: 0;

        &--mobile {
            position: absolute;
            right: 0;
            top: 100%;
            padding: 10px;
            background: $color-blue;
            width: 200px;
            display: none;
            text-align: center;

            .header__link{
                color:white;
                &:hover{
                    color:$color-green;
                }
                
            }
        }

        &--hamburguer {
            background: #005686;
            width: 50px;
            height: 50px;
            border: 0;
            border-radius: 0;
            padding: 6px;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            transition: 0.25s all ease;

            &:hover {
                transform: scale(1.1)
            }

            .hamburguer {
                position: relative;
                width: 80%;
                height: 2px;
                background-color: white;
                transition: 0.5s all ease-in-out;

                &:after,
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: white;
                    transition: 0.5s all ease-in-out;
                }

                &:before {
                    top: -7px;
                }

                &:after {
                    bottom: -7px;
                }

                &--openned {
                    transform: rotate(405deg);

                    &:after {
                        bottom: 0px;
                        transform: rotate(360deg)
                    }

                    &:before {
                        top: 0px;
                        transform: rotate(450deg);
                    }
                }
            }
        }
    }

    &__links--mobile li:last-child .header__link{
        border-bottom:0;
    }
}

@media(max-width:1399px){
    .header{
        &__link{
            font-size: 1.1rem;
        }
        &__logo{
            max-width:210px;
        }
    }
}
@media screen and (max-width: 1199px) {
    .header {
        &__logo {

            img {
                max-width: 85%;
                padding: 10px 20px 20px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .header {
        &__logo {

            img {
                max-width: 70%;
                padding: 10px 20px 20px;
            }
        }

        .d-flex {
            justify-content: flex-end
        }
    }
}

@media screen and (max-width: 767px) {
    .header {
        &__logo {
            img {
                max-width: 55%;
                padding: 8px 16px 16px;
            }
        }
    }
}

@media screen and (max-width: 467px) {
    .header {
        &__logo {
            img {
                max-width: 100%;
                padding: 10px 20px 20px;
            }
        }
    }
}