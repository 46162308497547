.como-funciona {
    background-color: $color-blue;
    
    .section-title {
        margin-bottom: 60px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &__circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background-color: white;
            z-index: 2;

            &:before {
                position: absolute;
                content: '';
                display: block;
                width: 47%;
                height: 2px;
                background-color: $color-green;
                left: 80%;
            }

            &--not-lined {
                &:before {
                    content: none;
                }
            }
        }

        &__title {
            margin: 30px 0;
            text-transform: uppercase;
            color: white;
            font-size: 2rem;
            font-weight: 300
        }

        &__phrase {
            color: rgba(218, 218, 218, 1);
            text-align: center;
            font-size: 1.1rem;
            line-height: 1.3
        }
    }
}


@media screen and (max-width: 1199px) {
    .como-funciona {
        &__item {
            &__circle {
                width: 160px;
                height: 160px;

                &:before {
                    width: 40%;
                    left: 85%;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .como-funciona {
        &__item {
            &__circle {
                width: 100px;
                height: 100px;

                img {
                    max-width: 60px;
                    max-height: 60px;
                }

                &:before {
                    width: 40%;
                    left: 85%;
                }
            }

            &__title {
                font-size: 1.5rem;
                margin: 20px 0;
            }

            &__phrase {
                font-size: 0.9rem;
            }

        }
    }
}


@media screen and (max-width: 767px) {
    .como-funciona {

        .section-title {
            margin-bottom: 40px;
        }

        &__item {
            margin: 20px 0;

            &__circle {
                width: 120px;
                height: 120px;

                img {
                    max-width: 80px;
                    max-height: 80px;
                }

                &:before {
                    content: none
                }
            }
        }
    }
}