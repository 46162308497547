.projetos {
    background-color: #efefef;
    padding-bottom: 130px;

    .section-title {
        margin-bottom: 60px;
    }

    &__item {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 400px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &:hover {
            .projetos__item__overlay {
                opacity: 1;
            }
        }

        &__overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($color-blue, 0.8);
            opacity: 0;
            transition: 1s opacity cubic-bezier(0.175, 0.885, 0.32, 1.275);
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            &:after,
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 15%;
                height: 4px;
                background: rgba(255, 255, 255, 0.9);
            }

            &:after {
                transform: rotate(90deg)
            }

            &__content-box {
                background-color: white;
                margin: 5%;
                width: 100%;
                align-self: flex-end;
                padding: 10px;
                text-align: center;

                h3 {
                    color: $color-blue;
                    font-size: 1.3rem;
                    text-transform: uppercase;
                }

                p {
                    margin-bottom: 0;
                    font-size: 1.2rem;
                    color: rgba(0, 0, 0, 0.4);
                }
            }
        }

        &__title {
            width: 90%;
            padding: 25px 0;
            background-color: $color-blue;
            text-align: center;
            color: white;
            text-transform: uppercase;
            font-size: 1.35rem;
            z-index: 2;
            margin: 5%;
            font-weight: bold;
            box-shadow: 11px 8px 0px #a9d407;
        }
    }
}

.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
    z-index: 9990;
    background: #000000e8;

    .close {
        width: 40px;
        height: 40px;
        position: fixed;
        top: 40px;
        right: 50px;
        background: url('../images/close.png');
        background-size: cover;
        cursor: pointer;
    }

}

#modalResidencial,
#modalComercial,
#modalIndustrial {

    z-index: 9999;

    .carrousel-gallery {

        .slider-holder {

            max-width: 50%;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .item {
                max-height: 100%;

                .img {}

                .description {
                    opacity: 0;
                    transition: opacity .2s linear;
                    text-align: center;
                    width: 50%;
                    background: white;
                    padding: 15px;
                    margin: 0 auto;
                    position: absolute;
                    bottom: 15px;
                    left: 50%;
                    transform: translateX(-50%);

                    p {
                        margin: 0;
                    }

                    h3 {
                        margin-bottom: 5px;
                        text-transform: uppercase;
                        font-size: 1.3rem;
                        font-weight: bold;
                        color: $color-blue
                    }
                }

                &:hover {
                    .description {
                        opacity: 1;
                        transition: opacity .5s linear;
                    }
                }

            }


        }

        .owl-nav button {
            display: inline-block;
            width: 60px;
            height: 60px;
            position: fixed;
            cursor: pointer;
            outline: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: -35px;

            span {
                display: none;
            }

            &:hover,
            &:focus {
                background: none;
                border: 0;
            }

            &.owl-prev {
                left: -80px;
                vertical-align: middle;
                margin-right: 10px;
                border-top: 30px solid transparent;
                border-bottom: 29px solid transparent;
                border-right: 25px solid #a7d300;
            }

            &.owl-next {
                vertical-align: middle;
                margin-left: 10px;
                border-top: 30px solid transparent;
                border-bottom: 29px solid transparent;
                border-left: 25px solid #a7d300;
                right: -80px;
            }
        }

        .owl-dots {
            button {
                outline: 0;
            }

            .owl-dot {

                span,
                &:hover span {
                    background: #6f6f6f;
                }

                &.active {

                    span,
                    &:hover span {
                        background: white;
                    }
                }
            }
        }

    }


}

@media screen and (max-width: 991px) {
    .projetos {
        padding-bottom: 60px;

        &__item {
            height: 300px;

            &__overlay {
                &__content-box {
                    h3 {
                        font-size: 1rem;
                    }

                    p {
                        font-size: 0.9rem
                    }
                }
            }

            &__title {
                font-size: 1.1rem;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .projetos {
        .section-title {
            margin-bottom: 30px;
        }

        &__item {
            
            height: 180px;
            max-width: 300px;
            margin: 20px auto;
        }
    }
}

@media(max-width:575px) {

    #modalResidencial,
    #modalComercial,
    #modalIndustrial {

        .carrousel-gallery {

            .slider-holder {
                .item {

                    .description {
                        display: none;
                    }
                }
            }
        }
    }
}