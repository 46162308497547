@import '../animations.scss';

.slideshow {
    padding: 0;
    position:relative;
    align-items:flex-start;
    min-height:calc(100vh - 60px);

    .carousel-inner{
        height:calc(100vh - 60px);
    }
    .carousel-item{
        overflow:hidden
    }
    &__img-animation {
        max-width:none;
        transform: scale(1);
        height:calc(100vh - 60px);
        animation: slideshow-img-downscaling 3s;
        width:100%;
    }
    &__txt-animation {
        animation: slideshow-img-upscaling 3s;
        margin-top: -80px;
    }

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1199px) {
    .slideshow {
        &__img-animation {
            max-width:none;
            transform: scale(1);
            height:calc(100vh - 60px);
            animation: slideshow-img-downscaling 3s;
            width:auto;
        }
        &__content {
            img {
                max-width: 50%;
            }
        }
    }
}

@media(max-width: 991px){

}

@media(max-width:575px){
    .slideshow__img-animation{
        
    }
    .slideshow__content img {
        max-width: 80%;
    }
}