.sobre-nos {
    background: url(../images/world-bg.png), white;
    background-position: 130% -300px, center;
    -webkit-background-size: contain, cover;
    background-size: 50%, cover;
    background-repeat:no-repeat;

    &__content {
        margin-top: 70px;
    }

    &__item {
        align-items: center;
        margin: 100px 0;

        &__circle {
            border-radius: 100%;
            background: $color-blue;
            width: 85px;
            height: 85px;
        }


        &__title {
            font-size: 1.2rem;
            color: $color-green;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0;
            margin-bottom: 7px;
        }

        &__content {
            margin: 0;
            font-size: 1rem;
            color: #3a3a3a;
            line-height: 1.3;
        }
    }

    .left {
        .row {
            flex-direction: row-reverse;
            text-align: right
        }
    }
}

@media screen and (max-width: 1399px) {
    .sobre-nos {
        .container{
            max-width: 1280px;
        }
        &__item{
            &__title{
                font-size: 1.5rem;
            }
            &__content{
                font-size: 1.1rem;
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .sobre-nos {
        &__item {
            &__circle {
                width: 60px;
                height: 60px
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .sobre-nos {

        &__content {
            margin-top: 50px;
        }

        img {
            max-height: 250px;
        }

        &__item {
            margin: 50px 0 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .sobre-nos {

        &__content {
            margin-top: 35px;
        }

        img {
            max-height: 200px;
        }

        &__item {
            margin: 40px 0;

            &__title {
                font-size: 1.2rem;
            }

            &__content {
                font-size: 0.9rem;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .sobre-nos {
        .left {
            .row {
                flex-direction: row;
                text-align: left;
            }
        }

        &__content {
            margin-top: 10px;
        }

        &__item {
            margin: 0;
            margin-top: 20px;

            .col-4 {
                display: flex;
                justify-content: flex-end
            }
        }
    }
}