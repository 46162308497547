.nossas-solucoes {
    background: url(../images/world-bg.png), white;
    background-position: -300px -200px, center;
    -webkit-background-size: contain, cover;
    background-size: 800px, cover;
    background-repeat: no-repeat, repeat;

    &__content {
        margin-top: 50px;
        width:100%;

        .solution {
            padding: 80px 0 0;

            &:nth-of-type(even) {
                .row {
                    flex-direction: row-reverse;
                }
                h3:before{ 
                    left: 0;
                    right:auto !important;
                }
            }

            &:nth-of-type(odd) {
                .content-col {
                    text-align: right;
                    justify-content: flex-end;
                }
            }

            .content-col {
                display: flex;
                align-items: center;
                img{
                    width:100%;
                }

                .service{
                    position:relative;
                    &__overlay{
                        position: absolute;
                        top: 0;
                        padding: 0 50px;
                        text-align: center;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        background: rgba(2, 85, 134, 0.88);
                        color: #ffffff;
                        font-weight: 500;
                        font-size: 1.1rem;
                        opacity:0;
                        transition: opacity 0.4s ease-in-out;

                        p{
                            margin:0;
                        }
                    }
                    &__overlay:hover{
                        opacity:1;
                    }
                }

            }
            .content-col.order-1{
                max-width: 100%;
                overflow: hidden;
            }
        }
        
    }

    &__title {
        font-size: 3rem;
        font-weight: 700;
        color: $color-blue;
        text-transform: uppercase;
        margin: 0;
        line-height: 0.95;
        position: relative;

        span {
            display: block;
            font-size: 5rem;
        }
        &:before{
                content: '';
                position: absolute;
                top: -55px;
                right: 0;
                background: url(../images/after.png);
                width: 50px;
                height: 50px;
                background-size: cover;
                background-repeat: no-repeat;
        }
    }
}


@media screen and (max-width: 1199px) {
    .nossas-solucoes {
      
        
        &__content {
            .solution{
                .content-col{
                    .service__overlay{
                        font-size:1rem;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .nossas-solucoes {
        &__title {
            font-size: 1.5rem;

            span {
                font-size: 2.75rem
            }
        }

        
        &__content {
            .solution{
                .content-col{
                    .service__overlay{
                        font-size:0.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .nossas-solucoes {
        &__title{
            line-height: 1.2;
        }
        
        &__content {
            margin-top: 40px;

           
            img{
                max-width:none;
                height: 225px;
                width: auto !important;
            }

            .solution {
                padding-top:30px;
                
                .row{
                    max-width: 90%;
                    margin: 0 auto;
                    
                }
                .title{
                    
                    background: #ececec;
                    padding: 20px 0;
                    z-index: 999;
                }
                
                h3:before{
                    right: 50% !important;
                    left: auto;
                    transform: translateX(50%);
                }

                &:nth-of-type(even) {
                    
                    h3:before{
                        right: 50% !important;
                        left: auto;
                        transform: translateX(50%);
                    }
                    .row {
                        flex-direction: row-reverse;
                    }
                }

                &:nth-of-type(odd) {
                    .content-col {
                        text-align: center;
                        justify-content: center;
                        
                    }
                }

                .content-col {
                    text-align: center;
                    justify-content: center;
                    margin: 0;
                    
                        .service__overlay{
                            font-size: 0.8rem;
                            max-width: 500px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .nossas-solucoes {
    background-position: -110px -125px, center;
    background-size: 300px, cover;

    .section-title{
        font-size: 1.5rem;
        max-width: 90%;
        margin: 0 auto;
    }
        &__title {
            font-size: 1.5rem;

            span {
                font-size: 1.9rem
            }
        }

        &__content {
            margin-top: 30px;
            overflow:hidden;
            .solution{
                .content-col{
                    .service__overlay{
                        max-width: 300px;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 0.8rem;
                        padding: 0 20px;
                    }
                }
            }

        }
    }
}