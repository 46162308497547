$color-blue: #025586;
$color-green: #a7d300;

@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700&display=swap');

* {
    box-sizing: border-box;
}

html{
    
  scroll-behavior: smooth;
}

.error-border{
    box-shadow: -6px 11px 0px 0px red !important;
}
body {
    position: relative;
    font-size: 14px;
    color: black;
    background: white;
    overflow-y: visible;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
}

img {
    display: block;
    max-width: 100%;
}

a {
    text-decoration: none;
    font-size: 1rem;

    &:hover {
        outline: none;
        border: none;
        text-decoration: none;
    }
}

section {
    padding: 90px 0 120px;
}

.section-title {
    font-size: 3.5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;

    &--blue {
        color: $color-blue;
    }

    &--green {
        color: $color-green;
    }
}

@media(min-width:1399px){
    .container{
        max-width:1240px;
    }
}
@media(max-width:1399px){
    html{
        font-size:85%;
    }
}


@media screen and (max-width: 991px) {
    section{
        padding:60px 0;
    }
    .section-title {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 767px) {
    .section-title {
        font-size: 2rem;
    }

    section {
        padding: 50px 0
    }

}

@media screen and (max-width:575px) {
    .section-title {
        font-size: 1.75rem;
    }
}

